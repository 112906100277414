import * as React from 'react';
import type { GatsbyBrowser, GatsbySSR } from 'gatsby';
import { getCookieConsentValue } from 'react-cookie-consent';
import './src/styles/global.css';

import '@fontsource/inter/latin-400.css';
import '@fontsource/inter/latin-300.css';
import '@fontsource/inter/latin-500.css';
import '@fontsource/inter/latin-600.css';
import '@fontsource/inter/latin-700.css';
import '@fontsource/inter/latin-900.css';

import '@fontsource/montserrat/latin-400.css';
import '@fontsource/montserrat/latin-300.css';
import '@fontsource/montserrat/latin-500.css';
import '@fontsource/montserrat/latin-600.css';
import '@fontsource/montserrat/latin-700.css';

import CookieBanner from './src/components/common/cookie-banner/CookieBanner';
import {
  GTM_COOKIE_NAME,
  CLARITY_COOKIE_NAME,
  COOKIE_ACCEPT,
} from './src/utils/cookie-consts';

function clarityLoad(c, l, a, r, i, t, y) {
  c[a] =
    c[a] ||
    function () {
      (c[a].q = c[a].q || []).push(arguments);
    };
  t = l.createElement(r);
  t.async = 1;
  t.src = 'https://www.clarity.ms/tag/' + i;
  y = l.getElementsByTagName(r)[0];
  y.parentNode.insertBefore(t, y);
}

function googleTagManagerLoad(w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
}

const loadAnalyticsScripts = () => {
  const gtmConsent = getCookieConsentValue(GTM_COOKIE_NAME);
  const clarityConsent = getCookieConsentValue(CLARITY_COOKIE_NAME);

  if (clarityConsent === COOKIE_ACCEPT) {
    clarityLoad(window, document, 'clarity', 'script', process.env.CLARITY_ID);
  }
  if (gtmConsent === COOKIE_ACCEPT) {
    googleTagManagerLoad(
      window,
      document,
      'script',
      process.env.GOOGLE_TM_DLN,
      process.env.GOOGLE_TM_ID,
    );
  }
};

const handleMouseMove = () => {
  loadAnalyticsScripts();
  window.removeEventListener('mousemove', handleMouseMove);
  window.removeEventListener('touchstart', handleMouseMove);
};

export const onInitialClientRender = () => {
  if (process.env.NODE_ENV === 'production') {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchstart', handleMouseMove);
  }
};

export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element }) => {
  return (
    <>
      {element}
      {process.env.NODE_ENV === 'production' && (
        <CookieBanner reloadAnalytics={loadAnalyticsScripts} />
      )}
    </>
  );
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = () => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 0);

  return false;
};
